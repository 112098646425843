import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import RegionsList from '../components/regionsList'
import Seo from '../components/seo'

class FindUsPage extends React.Component {

  render() {
    const { children } = this.props
    const regions = this.props.data.allContentfulRegion.nodes

    return (
      <Container>
        <Seo title="Nous trouver"  description="Où trouver nos produits à base de tofu." />
        <RegionsList regions={regions} />
        <main>{children}</main>
      </Container>
    )
  }
}

export default FindUsPage

export const pageQuery = graphql`
  query FindUsIndexQuery {
    allContentfulRegion {
      nodes {
        name
        slug
        stores {
          slug
          name
          longitudeLatitude {
            lat
            lon
          }
        }
      }
    }
  }
`
